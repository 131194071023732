import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import "vue3-paginate-z/dist/styles.css";
import Paginate from "vue3-paginate-z";

import Vue3DraggableResizable from 'vue3-draggable-resizable'
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'

import bottomNavigationVue from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";

import NumericInput from 'vue3-numeric-input';

// import SocketIO from 'socket.io-client'

const app = createApp(App)

var domain_web = 'https://adm.codebcr.com/api/'


app.config.globalProperties.link_web = 'https://adm.codebcr.com/'
app.config.globalProperties.api_login = domain_web + 'login'
app.config.globalProperties.api_logout = domain_web + 'logout'
app.config.globalProperties.api_register = domain_web + 'register'
app.config.globalProperties.api_checkLogin = domain_web + 'check-login'
app.config.globalProperties.api_getInput = domain_web + 'list-input'
app.config.globalProperties.api_CheckName = domain_web + 'check-user'
app.config.globalProperties.api_InfoUser = domain_web + 'info-user'
app.config.globalProperties.api_Changepass = domain_web + 'change_password'
app.config.globalProperties.api_subPoint = domain_web + 'reset_point'

app.config.globalProperties.api_result = 'https://member-api.aghippo168.com/apiRoute/table/getAllBac'
app.config.globalProperties.api_results = 'https://api.codebcr.com/list.php'
app.config.globalProperties.api_detailMinutes = 'https://api.codebcr.com/getapi.php'

app.config.globalProperties.api_detailMes = domain_web + 'group-detail-message'
app.config.globalProperties.api_sendMes = domain_web + 'add-message'

// app.config.globalProperties.socket = SocketIO('https://adm.codebcr.com', { transports: ['websocket', 'polling', 'flashsocket'], });

app.use(router)
app.use(VueAxios, axios)
app.use(Paginate)
app.use(VueSweetalert2)
app.use(bottomNavigationVue);
app.directive('numeric-input', NumericInput)
app.use(Vue3DraggableResizable)
app.mount('#app')
