<template>
    <div class="wrapper_home mg_top">
        <div class="container ner_responesize">
            <div class="wrapper_game_chicken">
                <div class="wp_item_game">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-6 item_game_propose" v-for="(item, index) in listData" :key="index">
                            <div class="img_pecent_game">
                                <router-link v-bind:to="{ name: 'Result_tool', params: { id: item.id, percent: item.pecent} }">
                                <!-- <a :href="'du-doan/' + item.id + '/' + item.pecent"> -->
                                    <img src="../assets/images/bgtable.png" alt="" class="img_bg">
                                    <img src="../assets/images/icon.png" alt="" class="img_lf">
                                    <div class="name_table">
                                        <span>Bàn {{item.table_name}}</span>
                                    </div>
                                    <div class="ratio_win">
                                        <span class="sp1">Tỷ lệ <br />thắng</span>
                                        <span class="sp2">{{ item.pecent }}%</span>
                                    </div>
                                <!-- </a> -->
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'List_tool',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            listData: [], 
        }
    },
    components: {
        
    },
    mounted () {
        
    },
    created () {
        this.getDataList()
    },
    computed: {
        
    },
    methods: {
        shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        },
        GetData () {
            const arrayRandom = [67, 69, 72, 75, 77, 81, 86, 89, 90, 93, 95, 100]
            arrayRandom.sort(function() {
                return 0.5 - Math.random();
            });
            let arr = []
            for (let i = 0; i < arrayRandom.length; i++) {
                arr[i] = { id: Number(i+1), name: 'Bàn ' + Number(i+1), pecent: arrayRandom[i] }
            }
            this.listData = arr
            // this.listgamecq9 = arr.slice().sort((a, b) => b.pecent - a.pecent)
        },
        getDataList () {
            this.axios.get(this.api_results).then((response) => {
                const arrayRandom = [65, 67, 70, 74, 76, 78, 81, 85, 86, 90, 93, 95, 97, 98, 100]
                arrayRandom.sort(function() {
                    return 0.5 - Math.random();
                });
                this.listData = response.data.data
                this.listData = this.listData.map(item => ({
                    ...item,
                    pecent: this.randomPecent()
                }));
            })
        },
        randomPecent() {
            return Math.floor(Math.random() * (100 - 65 + 1)) + 65;
        },
    }
}
</script>