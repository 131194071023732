<template>
    <div class="root_header_page">
        <header id="header" :class="'header has-sticky sticky-shrink ' + classHeader">
            <div class="container ner_responesize">
                <div :class="'menu_mobile ' + active">
                    <div class="info_mobile">
                        <span class="user_namelogin"><img src="../assets/images/name.png" alt="img user"> {{ us }}</span>
                        <span class="exp_user"><img src="../assets/images/point.png" alt="img user"> <b>{{ formatNumber(point) }}</b></span>
                        <img src="../assets/images/reset.png" @click="showModal = true" alt="btn reset" class="btn_change" title="Đổi mật khẩu">
                        <img src="../assets/images/logout.png" @click="logout" alt="btn logout" class="btn_logout" title="Đăng xuất">
                    </div>
                    <p style="opacity:0;z-index:-1;">
                        <router-link v-if="$route.path != '/'" class="nav-item" to='/' title="">
                            <img src="../assets/images/logo.png" class="logo-mobile" alt="logo">
                        </router-link>
                        <a v-else class="nav-item" href='/' title="">
                            <img src="../assets/images/logo.png" class="logo-mobile" alt="logo">
                        </a>
                    </p>
                    <img src="../assets/images/back.png" alt="back" class="back_page" @click="goBack" v-if="this.$route.name != 'Home_tool'">
                </div>
            </div>
            <!-- modal change password -->
            <div id="modal-example" class="modal-changepass">
                <div class="overlay" v-if="showModal" @click="showModal = false"></div>
                <div class="modal" v-if="showModal">
                    <div class="wp-modal-changepass">
                        <div class="header-modal">
                            <button class="close-modal_login" @click="showModal = false"><img src="../assets/images/close.png" alt=""></button>
                        </div>
                        <div class="content-modal_tab">
                            <div>
                                <div class="img_hacker_took">
                                    <p class="cloud-text">ĐỔI MẬT KHẨU</p>
                                </div>
                                <div class="frm_change">
                                    <form action="" method="post" @submit="changePass">
                                        <div class="item_inputchange">
                                            <p>Mật khẩu cũ</p>
                                            <div class="input_img">
                                                <input type="password" v-model="password_old" placeholder="Mật khẩu cũ">
                                                <img src="../assets/images/pass.png" alt="">
                                            </div>
                                        </div>
                                        <div class="item_inputchange">
                                            <p>Mật khẩu mới</p>
                                            <div class="input_img">
                                                <input type="password" v-model="password_new" placeholder="Mật khẩu mới">
                                                <img src="../assets/images/pass.png" alt="">
                                            </div>
                                        </div>
                                        <div class="item_inputchange">
                                            <p>Xác nhận mật khẩu</p>
                                            <div class="input_img">
                                                <input type="password" v-model="Confirm_password" placeholder="Xác nhận mật khẩu">
                                                <img src="../assets/images/pass.png" alt="">
                                            </div>
                                        </div>
                                        <div class="item_inputchange">
                                            <button type="submit">Xác nhận</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end modal change password -->
        </header>
        
        <!-- chat -->
        <div class="root_chat_header" v-if="this.$route.name == 'Result_tool'">
            <div class="wp_chat" v-if="chat == 1">
                <div class="chat_detail">
                    <div class="name_group_chat">
                        <h2><img src="../assets/images/iconhk.jpg" alt="" class="img_groupchat">Trò chuyện <span><img src="../assets/images/close.png" @click="hideChat" alt=""></span></h2>
                    </div>
                    <div class="detail_chat_mes" @scroll="scrollDataDetailMes" ref="scrollMess">
                        <div v-for="(item, index) in listDetailMes" :key="index">
                            <div :class="'item_chat item_chat_left message_' + item.id" v-if="item.user_from != userId">
                                <div class="nameuser_chat" v-if="isSameUsers(item, listDetailMes[index + 1])">{{ item.username }}</div>
                                <p>{{ item.content }}</p>
                            </div>
                            <div :class="'item_chat item_chat_right message_' + item.id" v-else>
                                <div class="nameuser_chat" v-if="isSameUsers(item, listDetailMes[index + 1])">{{ item.username }}</div>
                                <p>{{ item.content }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="frm_chat" style="display: none;">
                        <form action="" method="post" @submit="sendChatMes">
                            <div class="wp_inputchat_mes">
                                <input type="text" autocomplete="off" placeholder="Trò chuyện..." v-model="content_chat">
                                <div class="btn_sb_mes">
                                    <button type="submit">Gửi</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <img src="../assets/images/chat.png" @click="showChat" alt="Show chat" class="show_frm_chat" v-else>
        </div>
        <!-- end chat -->
        <!-- <CurvedBottomNavigation :options="options" v-model="selected" background-color='#07070A' foreground-color='' icon-color='#C01BA0'>
            <template #path="{ props }">
                <b>{{ props.path }}</b>
            </template>
        </CurvedBottomNavigation> -->
    </div>
</template>

<script>
import $ from 'jquery'
import SocketIO from 'socket.io-client'
export default {
    name: 'Header_tool',
    data () {
        return {
            active: '',
            selected: 3,
            classHeader: '',
            showModal: false,
            token: window.localStorage.getItem('token'),
            us: window.localStorage.getItem('userName'),
            fullname: '',
            picture: '',
            // options: [
            //     { id: 1, icon: "falive_icon", title: "", path: { name: "Home_tool" } },
            //     { id: 2, icon: "fasupport", title: "", path: {name: "ShootFish_tool"} },
            //     { id: 3, icon: "fahome_icon", title: "", path: { name: "Home_tool" } },
            //     { id: 5, icon: "favideo", title: "", path: { name: "Home_tool" } },
            //     { id: 4, icon: "falive18_icon", title: "", path: {name: "Info_tool"} },
            // ],
            others: '',
            password_old: '',
            password_new: '',
            Confirm_password: '',
            point: '',


            content_chat: '',
            userId: window.localStorage.getItem('userId'),
            orther: '',
            listDetailMes: [],
            chat: 1,
            idMesEnd: '',
            isMounted: true
        }
    },
    components: {
        
    },
    watch: {
        $route(to) {
            this.currentRouteName = to.name;
            if (this.currentRouteName == 'Result_tool') {
                setTimeout(() => {
                    var hg = $('.detail_chat_mes').prop('scrollHeight');
                    $('.detail_chat_mes').animate({ scrollTop: hg+100+'px' }, 5);
                }, 1000);
            }
        },
    },
    created () {
        window.addEventListener('scroll', this.handleScroll)
        this.getInfo()
        this.getDataDetailMes()
        this.connectChat()
    },
    beforeUnmount() {
    },
    methods: {
        logout () {
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_logout,
                {

                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                localStorage.clear();
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                setTimeout(() => {
                    window.location.href = '/'
                }, 1000);
            })
        },
        handleScroll () {
            if (window.scrollY > 100) {
                this.classHeader = 'active'
            }else{
                this.classHeader = ''
            }
        },
        getCheckLogin () {
            this.axios.get(this.api_checkLogin, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                if(response.data.data.user.is_login == 0){
                    this.logout()
                }else{
                    this.others = response
                }
            })
        },
        goBack() {
            this.$router.back();
            this.chat = 1
            this.isMounted = false
        },
        getInfo () {
            this.axios.get(this.api_InfoUser, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.point = response.data.data.user.exp
            }).catch((res) => {
                if(res.response.status == 401){
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 1000);
                }
                
            })
        },
        changePass (event) {
            event.preventDefault();
            $('.loading_show').show().fadeIn(10)
            if (this.password_new == this.Confirm_password) {
                this.axios.post(this.api_Changepass, 
                    {
                        password: this.password_old,
                        password_new: this.password_new,
                        password_cfm: this.Confirm_password
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                ).then((response) => {
                    $('#loading_show').css('display', 'none')
                    this.showModal = false
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.error,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
                return false;
            }else{
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Xác nhận mật khẩu không đúng',
                    showConfirmButton: false,
                    timer: 4000
                })
            }
        },
        formatNumber(value) {
            // vi-VN
            return value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
        },
        getDataDetailMes () {
            this.axios.get(this.api_detailMes,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                if (this.isMounted) {
                    if (response.data.data.data.length > 0) {
                        this.listDetailMes = response.data.data.data.reverse()
                        this.idMesEnd = this.listDetailMes[0].id
                    }else{
                        this.listDetailMes = []
                    }
                }
            })
        },
        scrollDataDetailMes () {
            this.$nextTick(function() {
                var hg = this.$refs.scrollMess;
                let heightScroll = hg.scrollTop
                if (heightScroll == 0 ) {
                    this.axios.get(this.api_detailMes + '?type_message=top&id=' + this.idMesEnd,
                        {
                            headers: {
                                Authorization: 'Bearer ' + this.token
                            }
                        }
                    ).then((response) => {
                        let dataAr = response.data.data.data.reverse()
                        if(dataAr.length > 0) {
                            this.listDetailMes = dataAr.concat(this.listDetailMes)
                            this.idMesEnd = this.listDetailMes[0].id
                        }
                    })
                }
            });
        },
        sendChatMes (e) {
            e.preventDefault();
            if(this.content_chat.trim() != ''){
                const form = new FormData();
                form.append('message', this.content_chat);
                this.axios.post(this.api_sendMes, 
                    form,
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                ).then((response) => {
                    this.content_chat = ''
                    this.orther = response
                    $('.wp_inputchat_mes input').focus()
                })
                return false;
            }else{
                $('.wp_inputchat_mes input').focus()
                this.content_chat = ''
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Vui lòng nhập nội dung',
                    showConfirmButton: false,
                    timer: 4000
                })
            }
            
        },
        connectChat () {
            var thisSk = this
            var socket = SocketIO(this.link_web,{ transports: ['websocket', 'polling', 'flashsocket'] });
            socket.on('chat:message',function(result){
                // console.log('1111111111', result[0]);
                let arrPush = {
                    action_edit: 1,
                    author: null,
                    author_reply: null,
                    avatar: null,
                    content: result[0].content,
                    content_reply: null,
                    created_at: result[0].created_at,
                    date: result[0].created_at,
                    edit: 0,
                    file: null,
                    file_audio: null,
                    file_name: null,
                    file_name_reply: null,
                    file_reply: null,
                    id: result[0].id,
                    image: null,
                    image_album: null,
                    image_reply: null,
                    pin: 0,
                    status: 0,
                    time: null,
                    time_audio: null,
                    total_like: 0,
                    type: 2,
                    type_call: 0,
                    type_forward: 0,
                    type_group: 0,
                    type_reply: "text",
                    type_sticker: 0,
                    user_from: result[0].user_from,
                    user_to: 1,
                    username: result[0].username,
                    view: 1
                }
                thisSk.listDetailMes = thisSk.listDetailMes.concat(arrPush)
                setTimeout(() => {
                    var hg = $('.detail_chat_mes').prop('scrollHeight');
                    $('.detail_chat_mes').animate({ scrollTop: hg+100 },500);
                }, 300);
            });
        },
        showChat () {
            this.chat = 1
            setTimeout(() => {
                var hg = $('.detail_chat_mes').prop('scrollHeight');
                $('.detail_chat_mes').animate({ scrollTop: hg+100 },500);
                $('.wp_inputchat_mes input').focus()
            }, 500);
        },
        hideChat () {
            this.chat = 0
        },
        formatDates (dateStr) {
            const dateObj = new Date(dateStr);
            const day = String(dateObj.getDate()).padStart(2, "0"); // Đảm bảo có 2 chữ số
            const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0
            const year = dateObj.getFullYear();
            return `${day}-${month}-${year}`;
        },
        isSameUsers (currentMessage, diffMessage) {
            var d = ''
            if(typeof diffMessage === 'undefined'){
                d = this.formatDates(currentMessage.date)
            }else{
                d = this.formatDates(diffMessage.date)
            }
            var d1 = this.formatDates(currentMessage.date)
            if(diffMessage && d == d1 && diffMessage.user_from === currentMessage.user_from){
                setTimeout(() => {
                    $('.message_' + diffMessage.id + ' .nameuser_chat').addClass('removeDivImg')
                }, 100);
            }
            return true;
        },
    }
}
</script>
<style>
</style>
